import { FC, useEffect, useReducer } from 'react'
import Context from './Context'
import {IAuth, IAuthAction} from "./types"
import { useNavigate } from 'react-router'
import { authorizedApiClient  } from '../../services/clients'
import axios, { AxiosError } from 'axios'


function reducer(state: IAuth, action: IAuthAction) {
    const { type, auth } = action

    switch (type) {
        case 'SET_AUTH':
            return auth
        case 'UPDATE_AUTH':
            return { ...state, ...auth }
        default:
            throw new Error()
    }
}


const Provider : FC<{persistKey: string, children: any}>= ({ persistKey = 'auth', children }) => {
    const persistAuth = JSON.parse(localStorage.getItem(persistKey) || '{}') as IAuth
    const navigate = useNavigate()
    const [auth, dispatch] = useReducer(reducer, persistAuth || {})

    var authorizedRequestInterceptor: number | undefined
    var authorizedResponseInterceptor: number | undefined

    const emptyRequest = { handlers: [] }
    const emptyResponse = { handlers: [] }
    const interceptors = authorizedApiClient.interceptors ?? { request: emptyRequest, response: emptyResponse }
    
    if (((interceptors.request ?? emptyRequest) as any).handlers.length == 0) {
        authorizedRequestInterceptor = authorizedApiClient.interceptors.request.use(
            async (config) => {
                const headers = config.headers || {}
                const storedAuth = JSON.parse(localStorage.getItem("auth") || "{}")

                headers.Authorization = `Token ${storedAuth.token}`

                if (config.data && (config.data.constructor === FormData)) {
                    headers["Content-Type"] = "multipart/form-data"
                }
        
                return config
            }
        )
    }
    

    if (((interceptors.response ?? emptyResponse) as any).handlers.length == 0) {
        authorizedResponseInterceptor = authorizedApiClient.interceptors.response.use(
            (response) => response,
            (error: AxiosError) => {
                console.debug(error)
                if (error.response?.status == 401) logout()

                return Promise.reject(error)
            }
        )
    }

    useEffect(() => {
        return (() => {
            authorizedRequestInterceptor && axios.interceptors.request.eject(authorizedRequestInterceptor)
            authorizedResponseInterceptor && axios.interceptors.response.eject(authorizedResponseInterceptor)
        })
    }, [])

    const setAuth = (auth: IAuth) => {
        dispatch({ type: 'SET_AUTH', auth })
    }

    const updateAuth = (auth: IAuth) => {
        dispatch({ type: 'UPDATE_AUTH', auth })
    }

    const logout = () => {
        console.trace("LOGOUT")
        setAuth({ isAuthenticated: false })
        navigate("/signin")
    }


    useEffect(() => {
        try {
            localStorage.setItem(persistKey, JSON.stringify(auth))
        } catch (error) {
            console.warn(error)
        }
    }, [auth, persistKey])

    return (
        <Context.Provider value={{ auth, setAuth, updateAuth, logout }}>
            {children}
        </Context.Provider>
    )
}

export default Provider